import {
    ICE_TRADE,
    GOS_ZAKUPKI,
    BUTB,
    BELOIL,
    BUTB_PPT,
    NAFTAN,
    MINSKTRANS,
    BELARUSKALI
} from "@/utils/parsersNames"

const state = {
    [ICE_TRADE]: {
        logs: [],
        links: {},
        page: {},
        isRequesting: false,
    },
    [BUTB]: {
        logs: [],
        links: {},
        page: {},
        isRequesting: false,
    },
    [GOS_ZAKUPKI]: {
        logs: [],
        links: {},
        page: {},
        isRequesting: false,
    },
    [BELOIL]: {
        logs: [],
        links: {},
        page: {},
        isRequesting: false,
    },
    [BUTB_PPT]: {
        logs: [],
        links: {},
        page: {},
        isRequesting: false,
    },
    [NAFTAN]: {
        logs: [],
        links: {},
        page: {},
        isRequesting: false,
    },
    [MINSKTRANS]: {
        logs: [],
        links: {},
        page: {},
        isRequesting: false,
    },
    [BELARUSKALI]: {
        logs: [],
        links: {},
        page: {},
        isRequesting: false,
    },
}
export default state