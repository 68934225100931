const ICE_TRADE = 'ICE_TRADE'
const GOS_ZAKUPKI = 'GOS_ZAKUPKI'
const BUTB = 'BUTB'
const BELOIL = 'BELOIL'
const BUTB_PPT = 'BUTB_PPT'
const NAFTAN = 'NAFTAN'
const MINSKTRANS = 'MINSKTRANS'
const BELARUSKALI = 'BELARUSKALI'

export {
    ICE_TRADE,
    GOS_ZAKUPKI,
    BUTB,
    BELOIL,
    BUTB_PPT,
    NAFTAN,
    MINSKTRANS,
    BELARUSKALI
}